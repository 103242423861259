import { render, staticRenderFns } from "./FirebaseAuthView.vue?vue&type=template&id=63f85a11&scoped=true&"
import script from "./FirebaseAuthView.vue?vue&type=script&lang=js&"
export * from "./FirebaseAuthView.vue?vue&type=script&lang=js&"
import style0 from "./FirebaseAuthView.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FirebaseAuthView.vue?vue&type=style&index=1&id=63f85a11&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f85a11",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VProgressLinear})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63f85a11')) {
      api.createRecord('63f85a11', component.options)
    } else {
      api.reload('63f85a11', component.options)
    }
    module.hot.accept("./FirebaseAuthView.vue?vue&type=template&id=63f85a11&scoped=true&", function () {
      api.rerender('63f85a11', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/FirebaseAuthView.vue"
export default component.exports