var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm.firebaseLoadingError
        ? _c("v-alert", { attrs: { type: "error", text: "", outlined: "" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("views.LoginView.firebase_loading_error")) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && !_vm.firebaseLoadingError,
              expression: "!loading && !firebaseLoadingError"
            }
          ]
        },
        [
          _vm.firebaseEmailExistent
            ? _c(
                "v-alert",
                { attrs: { type: "error", text: "", outlined: "" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "views.LoginView.firebase_existent_email_error",
                          { firebaseEmail: _vm.firebaseEmail }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.shouldShowEmailButton
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn-login white--text email-login-button firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button",
                      attrs: {
                        dark: "",
                        tag: "button",
                        to: {
                          name: "auth.email_login",
                          query: _vm.$route.query
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "firebaseui-idp-icon-wrapper" },
                        [
                          _c("img", {
                            staticClass: "firebaseui-idp-icon",
                            attrs: {
                              alt: "",
                              src:
                                "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"
                            }
                          })
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "firebaseui-idp-text firebaseui-idp-text-long"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("views.LoginView.login_with_identifier")
                            )
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "firebaseui-idp-text firebaseui-idp-text-short text-capitalize"
                        },
                        [_vm._v(_vm._s(_vm.$t("views.LoginView.email")))]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { attrs: { id: "firebaseui-auth-container" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }