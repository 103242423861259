var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height", style: _vm.computedBgStyle },
    [
      _c("div", { style: _vm.computedLyStyle }),
      _c(
        "v-container",
        {
          attrs: {
            "d-flex": "",
            "fill-height": "",
            "justify-center": "",
            "align-center": ""
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto px-7 pt-8", attrs: { width: "374px" } },
            [
              _vm.logo_url && _vm.shouldShowLogo
                ? _c("v-img", {
                    staticClass: "mx-auto mb-4 pb-4",
                    attrs: {
                      src: _vm.logo_url,
                      "max-width": "190",
                      "max-height": "190",
                      contain: ""
                    }
                  })
                : _vm._e(),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        type: "success",
                        text: "",
                        outlined: "",
                        value: _vm.logout && _vm.shouldShowAlerts,
                        transition: "fade-transition"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("views.LoginView.logout_success")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        type: "warning",
                        text: "",
                        outlined: "",
                        value: _vm.authNeeded && _vm.shouldShowAlerts,
                        transition: "fade-transition"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("views.LoginView.auth_needed")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        type: "error",
                        text: "",
                        outlined: "",
                        value: _vm.reAuth && _vm.shouldShowAlerts,
                        transition: "fade-transition"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("views.LoginView.reAuth")) + " "
                      )
                    ]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        type: "error",
                        text: "",
                        outlined: "",
                        value: _vm.tokenExpired && _vm.shouldShowAlerts,
                        transition: "fade-transition"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("views.LoginView.tokenExpired")) +
                          " "
                      )
                    ]
                  ),
                  _c("router-view", {
                    on: {
                      hideWelcomeBanner: function($event) {
                        _vm.shouldShowWelcomeBanner = false
                      },
                      hideLogo: function($event) {
                        _vm.shouldShowLogo = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }