<template>
    <div class="fill-height" :style="computedBgStyle">
        <div :style="computedLyStyle"></div>
        <v-container d-flex fill-height justify-center align-center>

            <v-card width="374px" class="mx-auto px-7 pt-8">
                <v-img v-if="logo_url && shouldShowLogo" :src="logo_url" max-width="190" max-height="190" class="mx-auto mb-4 pb-4" contain/>

                <!-- [TODO] modificar em uma proxima FID -->
                <!-- <WelcomeBanner class="ma-0" v-if="shouldShowWelcomeBanner"/> -->
                <v-card-text>

                    <v-alert type="success" text outlined :value="logout && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.LoginView.logout_success') }}
                    </v-alert>

                    <v-alert type="warning" text outlined :value="authNeeded && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.LoginView.auth_needed') }}
                    </v-alert>

                    <v-alert type="error" text outlined :value="reAuth && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.LoginView.reAuth') }}
                    </v-alert>

                    <v-alert type="error" text outlined :value="tokenExpired && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.LoginView.tokenExpired') }}
                    </v-alert>

                    <router-view @hideWelcomeBanner="shouldShowWelcomeBanner = false" @hideLogo="shouldShowLogo = false"></router-view>

                </v-card-text>
            </v-card>

        </v-container>
    </div>
</template>

<script>
import { mapGetters }       from 'vuex'
import HasBackgroundMixin   from '@/mixins/HasBackgroundMixin'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
// import WelcomeBanner        from './WelcomeBanner'

export default {
    name: 'FirebaseAuthView',
    props: {
        // Indica se o usuário acabou de fazer logout
        logout: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        authNeeded: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        reAuth: Boolean,

        // Indica se o token expirou
        tokenExpired: Boolean,
    },
    mixins: [ HasBackgroundMixin, HasErrorHandlerMixin ],
    // components: { WelcomeBanner },
    data: vm => ({
        shouldShowAlerts       : true,
        shouldShowWelcomeBanner: true,
        shouldShowLogo         : true,
    }),
    mounted() {
        // Depois de 5s os alertas somem
        setTimeout(() => {
            this.shouldShowAlerts = false
        }, 5000)
    },
    computed: {
        ...mapGetters({
            logo_url: 'company/logo_url',
        }),
    },
    watch: {
        '$route.name'() {
            this.shouldShowWelcomeBanner = true
            this.shouldShowLogo = true
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title {
    font-size: 28px;
}
</style>