<template>
    <div>
        {{ $t('globals.loading') }}
    </div>
</template>

<script>
export default {
    name: 'LinkLogin',
    data: vm => {
        return {
            loading: false,
        }
    },
    async created() {
        await this.login()
    },
    methods: {
        async login() {
            this.loading = true

            await this.$store.dispatch('auth/linkLogin', { 
                token: this.$route.query.token, 
                email: this.$route.query.email
            }).catch(err => {
                this.$router.push({ name: 'auth.email_login' })
            })

            this.loading = false
        }
    }
}
</script>