<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"/>
        <v-alert type="error" text outlined v-else-if="firebaseLoadingError">
            {{ $t('views.LoginView.firebase_loading_error') }}
        </v-alert>

        <div v-show="!loading && !firebaseLoadingError">
            <v-alert type="error" text outlined v-if="firebaseEmailExistent">
                {{ $t('views.LoginView.firebase_existent_email_error', { firebaseEmail }) }}
            </v-alert>

            <div class="text-center" v-if="shouldShowEmailButton">
                <router-link
                    dark tag="button"
                    :to="{ name: 'auth.email_login', query: $route.query }"
                    class="btn-login white--text email-login-button firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
                >
                    <span class="firebaseui-idp-icon-wrapper"><img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"></span>
                    <span class="firebaseui-idp-text firebaseui-idp-text-long">{{ $t('views.LoginView.login_with_identifier') }}</span>
                    <span class="firebaseui-idp-text firebaseui-idp-text-short text-capitalize">{{ $t('views.LoginView.email') }}</span>
                </router-link>
            </div>

            <div id="firebaseui-auth-container"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters }       from 'vuex'
import HasBackgroundMixin   from '@/mixins/HasBackgroundMixin'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RateColor            from '@/shared/mixins/RateColor'
import $bugsnag             from '@/services/bugsnag'

export default {
    name: 'FirebaseAuthView',
    mixins: [ HasBackgroundMixin, HasErrorHandlerMixin, RateColor ],
    data: vm => ({
        loading              : true,
        shouldShowEmailButton: true,
        firebaseLoadingError : false,
    }),
    props: {
        firebaseEmailExistent: Boolean,
        firebaseEmail: String,
    },
    mounted() {
        this.initFirebaseUI()
    },
    methods: {
        initFirebaseUI() {
            this.loading = true

            if (this.$lodash.isEmpty(window.firebaseui)) {
                // É importante que saibamos quando ocorreu um problema de carregamento no Firebase
                if ($bugsnag.isEnabled())
                    $bugsnag.getInstance().notify('Error loading Firebase')

                this.firebaseLoadingError = true
                this.loading              = false

                return
            }

            // FirebaseUI config.
            var uiConfig = {
                // signInSuccessUrl: '<url-to-redirect-to-on-success>',
                signInOptions: [
                    window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    window.firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                    // [FID-653] Comentado temporariamente enquanto não habilitamos o telefone
                    // {
                    //     provider: window.firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    //     defaultCountry: 'BR',
                    //     recaptchaParameters: {
                    //         type: 'image',
                    //         size: 'invisible',
                    //         badge: 'bottomleft'
                    //     },
                    //     whitelistedCountries: ['+55']
                    // },
                    // window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    // {
                    //     provider: window.firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    //     recaptchaParameters: {
                    //         type: 'image', // or 'audio'
                    //         size: 'normal', // or 'invisible' or 'compact'
                    //         badge: 'bottomleft' // or 'bottomright' or 'inline' applies to invisible.
                    //     },
                    //     defaultCountry: '+55', // Set default country to Brazil (+55).
                    //     loginHint: '+55 (00) 00000-0000',
                    //     whitelistedCountries: ['+55'],
                    // },
                ],
                tosUrl: this.legal ? this.legal.terms_url : '',
                privacyPolicyUrl: this.legal ? this.legal.privacy_policy_url : '',
                credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
                signInFlow: 'popup',
                callbacks: {
                    // Esta função precisa ser diretamente e não por referência, caso contrário não é executada
                    signInSuccessWithAuthResult: (authResult, redirectUrl) => { 
                        // User successfully signed in.
                        // Return type determines whether we continue the redirect automatically
                        // or whether we leave that to developer to handle.

                        // O login e redirecionamento estão sendo tratados no serviço do firebase
                        return false
                    },
                    signInFailure: this.signInFailure,
                    uiShown: this.uiShown,
                    signInSuccessUrl: false,
                },
            }

            // Initialize the FirebaseUI Widget using window.Firebase.
            this.ui = window.firebaseui.auth.AuthUI.getInstance() || new window.firebaseui.auth.AuthUI(window.firebase.auth());

            // The start method will wait until the DOM is loaded.
            this.ui.start('#firebaseui-auth-container', uiConfig)
        },

        signInFailure(error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            console.error('error', error) // eslint-disable-line no-console

            this.loading = false

            // return handleUIError(error);
            return false
        },

        uiShown() {
            // [TODO] Ainda não vai ter login por telefone
            // // Quando clica no botão de telefone, deve esconder o login por email
            // let phoneButton = document.querySelector('#firebaseui-auth-container .firebaseui-id-idp-button.firebaseui-idp-phone')

            // // console.log('phoneButton', phoneButton)

            // phoneButton.addEventListener('click', event => {
            //     this.shouldShowEmailButton = false

            //     // let cancelButton = document.querySelector('#firebaseui-auth-container .firebaseui-id-idp-button.firebaseui-idp-phone')
            //     // 'firebaseui-id-secondary-link firebaseui-button mdl-button mdl-js-button mdl-button--primary'
            //     // 'firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored'
            // })

            // The widget is rendered.
            // Hide the loader.
            this.loading = false
        },

        onEmailSubmit(auth) {
            // console.log('onEmailSubmit', auth)
        },
    },
    computed: {
        ...mapGetters({
            legal: 'company/legal',
        }),
    },
}
</script>

<style lang="scss">
#firebaseui-auth-container {
    ul, ol {
        padding-left: 0;
    }

    button[data-provider-id="google.com"] {
        box-shadow: none !important;
        border: 1px solid #a0a0a0;
        border-radius: 4px;
        min-height: 48px;
    }

    button[data-provider-id="facebook.com"] {
        box-shadow: none !important;
        border-radius: 4px;
    }

   @media screen and (max-width: 375px) {
    .firebaseui-card-content {
        padding: 0 !important;
    }
}
}
</style>

<style lang="scss" scoped>
.login-form-container {
    max-width: 300px;
    margin: auto;
}
.btn-login {
    box-shadow: none !important;
    border-radius: 4px;
}

.email-login-button {
    direction: ltr;
    font-weight: 500;
    height: auto !important;
    line-height: normal;
    max-width: 220px;
    min-height: 48px;
    padding: 8px 16px !important;
    text-align: left;
    width: 100%;

    background-color: #db4437;
}
</style>
